import React, { useState } from "react";
import { Button, message } from "@centrate-io/barn";
import { Actions } from "_styleguide";
import { Avatar, Title } from "_styleguide";
import { IconLock } from "@tabler/icons-react";
import { useApolloClient } from "@apollo/client";
import { userVerifySSN } from "_graphql/mutations/application";
import { useNavigate } from "react-router-dom";
import "./VerifySSN.scss";

function VerifySSN(props) {
  const [selected, setSelected] = useState();
  const client = useApolloClient();
  const navigate = useNavigate();

  const clickVerify = async () => {
    props.setLoadingReport(true);
    const stateResponse = await client.mutate({
      variables: {
        application_id: props?.form?.id,
        ssn: selected,
      },
      mutation: userVerifySSN,
    });
    if (stateResponse?.data?.userVerifySSN === "success") {
      await props.checkState();
    } else {
      navigate("/invalid");
      message.error("Invalid selection, please contact us");
    }
  };
  return (
    <div className="step-start step-credit-verify">
      <Avatar icon={<IconLock />} />
      <Title>Verify Your Identity for a Secure Credit Check</Title>
      <p className="ssn-verify-info">
        This step will confirm your identity and will NOT affect your credit
        score. <br />
        <b>Please select the last 4 digits of your SSN from the list below.</b>
      </p>
      <div className="select-ssn">
        {props.ssns?.map((s, i) => (
          <div
            className={`ssn-item ${selected === s ? "selected" : ""}`}
            key={`select-ssn-${i}`}
            onClick={() => setSelected(s)}
          >
            <div className="ssn-radio">
              <div />
            </div>
            <h4>
              <span>X</span>
              <span>X</span>
              <span>X</span>
              <span>-</span>
              <span>X</span>
              <span>X</span>
              <span>-</span>
              <span>
                <b>{s[0]}</b>
              </span>
              <span>
                <b>{s[1]}</b>
              </span>
              <span>
                <b>{s[2]}</b>
              </span>
              <span>
                <b>{s[3]}</b>
              </span>
            </h4>
          </div>
        ))}
      </div>
      <Actions
        {...props}
        form={props.form}
        noPrevious
        noPowered
        showLogo
        customNext={
          <Button
            disabled={!selected}
            type="primary"
            size="large"
            block
            onClick={clickVerify}
            className="green-btn"
          >
            Verify your identity
          </Button>
        }
      />
    </div>
  );
}

export default VerifySSN;
