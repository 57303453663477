import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Applications from "./Applications/Applications";
import Subscriptions from "./Subscriptions/Subscriptions";
import Application from "./Application/Application";
import Companies from "./Companies/Companies";
import Users from "./Users/Users";
import Payments from "./Payments/Payments";
import Analytics from "./Analytics/Analytics";
import Wizard from "./Wizard/Wizard";
import Workflows from "./Workflows/Workflows";
import EditWorkflow from "./Workflows/_components/EditWorkflow/EditWorkflow";
import Company from "./_components/Company/Company";
import Settings from "./_components/Settings/Settings";
import MyTasks from "./_components/MyTasks/MyTasks";
import { Button } from "@centrate-io/barn";
import {
  IconFiles,
  IconReceipt2,
  IconSettings,
  IconCash,
  IconPlus,
  IconSubtask,
  IconSitemap,
  IconChartHistogram,
} from "@tabler/icons-react";
import { Header, Text } from "@centrate-io/barn";
import { Layout, Menu } from "antd";
import { getSelectedKeys } from "_helpers/routing";
import { useApolloClient } from "@apollo/client";
import { getMyTasksCount } from "App/Admin/_graphql/queries/task";
import { numberWithCommas } from "_assets/js/helpers";
import { masqueradeBack } from "App/Admin/_graphql/mutations/user";
import "./Admin.scss";

const { Content, Sider } = Layout;

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});
const items = [
  getItem(<Link to="/">Leads</Link>, "leads", <IconFiles />),
  getItem(
    <Link to="/customers">Customers</Link>,
    "customers",
    <IconReceipt2 />,
  ),
  // getItem(<Link to="/analytics">Analytics</Link>, "4", <IconChartAreaLine />),
];

function Admin(props) {
  const client = useApolloClient();
  const [collapsed, setCollapsed] = useState(false);
  const [currentCount, setCurrentCount] = useState();
  const [crumbs, setCrumbs] = useState();
  const [tasksOpen, setTasksOpen] = useState(false);
  const [reset, setReset] = useState(true);
  const [settingsModal, setSettingsModal] = useState(false);
  const [taskCount, setTaskCount] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [templates, setTemplates] = useState({
    data: [],
    reload: null,
  });

  let location = useLocation();
  const path = location?.pathname;
  const tablePaths = ["/"];
  const isWizard = path.includes("wizard");
  const isWorkflows = path.includes("workflows");
  const isEditWorkflow = path.includes("workflows/");
  const isAnalytics = path.includes("analytics");

  const unmasq = async () => {
    const queryData = await client.mutate({
      mutation: masqueradeBack,
    });
    const token = queryData?.data?.masqueradeBack;
    if (token && props.login) {
      props.login(token);
      fetchTaskCount();
    }
  };

  const fetchTaskCount = async () => {
    const queryData = await client.query({
      query: getMyTasksCount,
    });
    let todoTasks = queryData?.data?.getMyTasks?.todo || [];
    setTaskCount(todoTasks?.length || 0);
  };

  useEffect(() => {
    fetchTaskCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const globalProps = {
    setCurrentCount,
    setCrumbs,
    user: props.user,
    templates,
    setTemplates,
    setIsModifying,
  };

  let userItems = [
    { type: "divider" },
    // getItem(<Link to="/users">Users</Link>, "users", <IconUsers />),
  ];

  let companies = [];
  let payments = [];
  const canManageCompanies =
    props?.user?.role === "admin" ||
    props?.user?.role === "super" ||
    props?.user?.company?.is_owner === "owner";
  const isSuperAdmin = props?.user?.role === "super";
  if (canManageCompanies) {
    payments = [
      getItem(<Link to="/payments">Payments</Link>, "payments", <IconCash />),
      getItem(
        <Link to="/workflows">Workflows</Link>,
        "workflows",
        <IconSubtask />,
      ),
    ];
  }
  if (isSuperAdmin) {
    payments.push(
      getItem(
        <Link to="/analytics">Analytics</Link>,
        "analytics",
        <IconChartHistogram />,
      ),
    );
  }

  const canMangeSettings =
    props?.user?.role !== "user" && props?.user?.role !== "removed";

  if (props?.user?.role === "user") {
    userItems = [];
    companies = [];
  }

  let settings = [];
  if (canMangeSettings) {
    settings = [
      getItem(
        <div onClick={() => setSettingsModal(true)}>Settings</div>,
        "settings",
        <IconSettings />,
      ),
    ];
  }

  const showMyTasks = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setTasksOpen(true);
    }, 1);
  };
  let tasks = [
    getItem(
      <div onClick={tasksOpen ? () => setTasksOpen(false) : showMyTasks}>
        My Tasks
        {taskCount > 0 ? (
          <span className="admin-task-count">
            {numberWithCommas(taskCount)}
          </span>
        ) : null}
      </div>,
      "tasks",
      <IconSitemap />,
    ),
    { type: "divider" },
  ];

  const menuKeys = getSelectedKeys(location?.pathname, tasksOpen, isModifying);

  const isMasq = props?.user?.masq ? true : false;

  return (
    <Layout className={isMasq ? "layout-admin masq" : "layout-admin"} hasSider>
      {!reset ? (
        <MyTasks
          onClose={() => setTasksOpen(false)}
          open={tasksOpen}
          task={tasksOpen}
          collapsed={collapsed}
          setTaskCount={setTaskCount}
        />
      ) : null}
      {settingsModal ? (
        <Settings
          {...props}
          {...globalProps}
          fetchTaskCount={fetchTaskCount}
          canManageCompanies={canManageCompanies}
          removeModal={() => setSettingsModal(false)}
        />
      ) : null}
      <Sider
        width={240}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Company user={props?.user} collapsed={collapsed} />
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={menuKeys}
          items={[
            ...tasks,
            ...items,
            ...payments,
            ...userItems,
            ...companies,
            ...settings,
          ]}
        />

        <Link to="/wizard/new">
          <Button className="wizard-btn">
            <IconPlus /> New Application
          </Button>
        </Link>
        {isMasq ? (
          <div id="masq">
            <b>Warning:</b> You are logged in as a different user -{" "}
            <span onClick={unmasq}>Go Back</span>
          </div>
        ) : null}
      </Sider>
      <Layout className={`site-layout ${collapsed ? "collapsed" : ""}`}>
        <Content
          className={`site-content ${tablePaths.includes(path) && "sheet"} ${isWizard && "wizard"} ${isWorkflows && "workflows"} ${isAnalytics && "analytics"} ${isEditWorkflow && "edit-workflow"}`}
        >
          <div className="admin-header">
            {crumbs ? (
              <div className="crumbs">
                <Header type="display" size="5">
                  {crumbs?.icon}
                  {crumbs?.title}
                  <span className="current-count">
                    {currentCount && currentCount > 0
                      ? ` (${currentCount})`
                      : null}
                  </span>
                  {crumbs?.tag}
                </Header>
                <Text type="helper">{crumbs?.description}</Text>
              </div>
            ) : null}
          </div>
          <div className="admin-body">
            <Routes>
              <Route
                exact
                path="/"
                id="leads"
                element={<Applications {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/customers/:tab?"
                id="customers"
                element={<Subscriptions {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/workflows"
                id="workflows"
                element={<Workflows {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/workflows/:id"
                id="edit-workflow"
                element={<EditWorkflow {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/customer/:id/:tab?"
                id="customer"
                element={<Application {...props} {...globalProps} />}
              />
              {canManageCompanies && (
                <Route
                  exact
                  path="/payments"
                  id="payments"
                  element={<Payments {...props} {...globalProps} />}
                />
              )}
              {canManageCompanies && (
                <Route
                  exact
                  path="/analytics/:tab?"
                  id="analytics"
                  element={<Analytics {...props} {...globalProps} />}
                />
              )}
              {canManageCompanies && (
                <Route
                  exact
                  path="/companies"
                  id="companies"
                  element={<Companies {...props} {...globalProps} />}
                />
              )}
              <Route
                exact
                path="/users"
                id="users"
                element={<Users {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/wizard/:applicationId/:tab?"
                id="users"
                element={<Wizard {...props} {...globalProps} />}
              />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Admin;
