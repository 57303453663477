import React from "react";
import { Actions, FormInput, InputLabel, Title, Avatar } from "_styleguide";
import { IconIdBadge } from "@tabler/icons-react";
import { validateContact } from "_helpers/validateForm";

function Contact(props) {
  const { form, u } = props;

  const validation = validateContact(form);

  return (
    <div className="step-start">
      <Avatar icon={<IconIdBadge />} />
      <Title>
        Great thanks!
        <br />
        Let’s get to know who you are bit more
      </Title>
      <div className="app-form">
        <div className="form-row">
          <div>
            <InputLabel htmlFor="first_name" {...validation.first_name}>
              First name *
            </InputLabel>
            <FormInput
              id="first_name"
              value={form.first_name}
              onChange={(e) => u("first_name", e.target.value)}
              size="large"
              placeholder="e.g. James..."
              {...validation.first_name}
            />
          </div>
          <div>
            <InputLabel htmlFor="last_name" {...validation.last_name}>
              Last name *
            </InputLabel>
            <FormInput
              id="last_name"
              value={form.last_name}
              onChange={(e) => u("last_name", e.target.value)}
              size="large"
              placeholder="e.g. Ford..."
              {...validation.last_name}
            />
          </div>
        </div>
        <div className="form-item">
          <InputLabel htmlFor="email" {...validation.email}>
            Email address *
          </InputLabel>
          <FormInput
            id="email"
            value={form.email}
            onChange={(e) => u("email", e.target.value?.toLowerCase())}
            size="large"
            placeholder="e.g. jford@gmail.com..."
            {...validation.email}
          />
        </div>
      </div>
      <Actions
        {...props}
        form={form}
        disabledNext={!validation.allValid}
        noPrevious
        noPowered
        showLogo
      />
    </div>
  );
}

export default Contact;
