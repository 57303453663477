import React, { useState } from "react";
import { InfoWindow } from "_styleguide";
import { useNavigate } from "react-router-dom";
import OfferCard from "../OfferCard/OfferCard";
import { PLAN_INCLUDES_SHORT } from "_helpers/plan";
import { IconStarFilled } from "@tabler/icons-react";
import { Button, message } from "@centrate-io/barn";
import { saveForm } from "_styleguide/Actions/saveForm";
import { getErrors } from "_helpers/api";
import { timeout } from "_assets/js/helpers";
import "./InfoPopup.scss";

function InfoPopup(props) {
  const navigate = useNavigate();
  const [savingOffer, setSavingOffer] = useState(false);
  const { plan, actionProps } = props;

  const handleClick = async () => {
    const form = actionProps.form;
    try {
      const newPlan = { ...form.plan, ...plan };
      delete newPlan.title;
      setSavingOffer(newPlan?.months);
      await timeout(500);
      actionProps.u("plan", newPlan);
      await saveForm(actionProps.client, { ...form, plan: newPlan }, "offers");
      setSavingOffer(false);
      navigate(actionProps?.next);
    } catch (err) {
      message.error(getErrors(err) || "Error selecting offer, try again");
      setSavingOffer(false);
    }
  };

  return (
    <InfoWindow
      className={`offer-iw duration-${plan.months}`}
      onClose={props.closeWindow}
    >
      <OfferCard
        actionProps={props.actionProps}
        title={plan.title}
        duration={plan.months}
        cost={plan.amount}
        popular
        selected
      />
      <div className="plan-includes">
        <h5>ALL PLANS INCLUDE:</h5>
        <div className="bullets">
          {PLAN_INCLUDES_SHORT.map((p, i) => (
            <div key={"pos-" + i} className="bullet">
              <IconStarFilled />
              {p}
            </div>
          ))}
        </div>
      </div>
      <Button
        size="large"
        loading={savingOffer === plan.months}
        onClick={handleClick}
        block
      >
        Confirm Your Plan
      </Button>
    </InfoWindow>
  );
}

export default InfoPopup;
