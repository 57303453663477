import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { Button, message } from "@centrate-io/barn";
import {
  FormInput,
  InputLabel,
  Flex,
  Select,
  TextArea,
  TaskBadge,
  DatePicker,
  TeamAvatar,
  UserAvatar,
  InputNumber,
} from "_styleguide";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { validateCustomTask } from "App/Admin/_helpers/validateForm";
import { myUsers } from "App/Admin/_graphql/queries/user";
import { getTeams } from "App/Admin/_graphql/queries/team";
import { getTaskTemplates } from "App/Admin/_graphql/queries/task";
import { useApolloClient } from "@apollo/client";
import { saveCustomerTask } from "App/Admin/_graphql/mutations/task";
import { getErrors } from "_helpers/api";
import "./CustomTask.scss";

const defaultForm = {
  id: null,
  title: undefined,
  type: undefined,
  instructions: undefined,
  start: undefined,
  delay: 0,
  due_timestamp: undefined,
  assigned_user_id: undefined,
  assigned_team_id: undefined,
  template_id: null,
  assigned_user: null,
  template: null,
};

dayjs.extend(dayjsPluginUTC);

function CustomTask(props) {
  const client = useApolloClient();
  const [saving, setSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [form, setForm] = useState(props.task?.id ? props.task : defaultForm);
  const jsonForm = props.task?.id
    ? JSON.stringify(props.task)
    : JSON.stringify(defaultForm);
  const isEditing = props.task?.id ? true : false;
  const hasChanged = JSON.stringify(form) !== jsonForm;

  // Create Task Template
  const createCustomTask = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { application_id: props.applicationId, ...form },
        mutation: saveCustomerTask,
      });
      message.success(`Task ${isEditing ? "saved" : "created"}`);
      if (props.reload) props.reload();
      props.onClose();
      setSaving(false);
      setForm(defaultForm);
    } catch (err) {
      message.error(getErrors(err) || "Error saving task, try again");
      setSaving(false);
    }
  };

  const fetchUsers = async () => {
    const queryData = await client.query({
      query: myUsers,
    });
    setUsers(queryData?.data?.myUsers || []);
  };
  const fetchTeams = async () => {
    const queryData = await client.query({
      query: getTeams,
    });
    setTeams(queryData?.data?.getTeams || []);
  };
  const fetchTemplates = async () => {
    const queryData = await client.query({
      query: getTaskTemplates,
    });
    setTemplates(queryData?.data?.getTaskTemplates || []);
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const validation = validateCustomTask(form);
  let templateOptions = {};
  templates.forEach((t) => {
    if (!templateOptions[t.type]) {
      templateOptions[t.type] = [t];
    } else {
      templateOptions[t.type].push(t);
    }
  });

  const changeTemplate = (templateId) => {
    const templateData = templates.find((t) => t.id === templateId);
    if (!templateData) {
      updateForm("template_id", null);
      const editedform = { ...form };
      editedform.template_id = null;
      editedform.title = undefined;
      editedform.type = undefined;
      editedform.instructions = undefined;
      editedform.template = null;
      setForm(editedform);
    } else {
      const editedform = { ...form };
      editedform.template_id = templateId;
      editedform.title = templateData.title;
      editedform.type = templateData.type;
      editedform.instructions = templateData.instructions;
      editedform.template = {
        title: templateData.title,
        type: templateData.type,
        instructions: templateData.instructions,
      };
      setForm(editedform);
    }
  };
  const changeAssigned = (assignedId) => {
    const userData = users?.find((t) => t.id === assignedId);
    const teamData = teams?.find((t) => t.id === assignedId);
    if (!userData && !teamData) {
      const editedform = { ...form };
      editedform.assigned_user_id = null;
      editedform.assigned_team_id = null;
      editedform.assigned_user = {};
      setForm(editedform);
    } else if (teamData) {
      const editedform = { ...form };
      editedform.assigned_user_id = null;
      editedform.assigned_team_id = assignedId;
      editedform.assigned_user = {};
      setForm(editedform);
    } else {
      const editedform = { ...form };
      editedform.assigned_user_id = assignedId;
      editedform.assigned_team_id = null;
      editedform.assigned_user = {
        name: userData.name,
      };
      setForm(editedform);
    }
  };

  const changeStart = (value) => {
    if (value === "now") {
      const editedform = { ...form };
      editedform.start = value;
      editedform.delay = 0;
      editedform.due_timestamp = dayjs().utc();
      setForm(editedform);
    } else {
      const editedform = { ...form };
      editedform.start = value;
      editedform.delay = 1;
      setForm(editedform);
    }
  };
  const changeDelay = (value) => {
    const actualValue = parseInt(value);
    if (isNaN(value) || actualValue < 0) {
      updateForm("delay", 0);
    } else {
      updateForm("delay", isNaN(actualValue) ? 0 : actualValue);
    }
  };

  const dateFormat = "MM/DD/YYYY";
  const dateChange = async (v) => {
    updateForm("due_timestamp", v.utc());
  };

  useEffect(() => {
    fetchTemplates();
    fetchUsers();
    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      width={456}
      onClose={props.onClose}
      open={props.open}
      maskClosable={false}
      className="custom-task-drawer"
      rootClassName="custom-task-parent"
      destroyOnClose={true}
    >
      <div className="new-task-title">{isEditing ? "Edit" : "Add"} Task</div>
      <div className="new-task-form">
        <Flex vertical={true}>
          <InputLabel htmlFor="template_id">Start From Template</InputLabel>
          <Select
            value={form.template_id}
            onChange={changeTemplate}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
          >
            <Select.Option value={null}>---</Select.Option>
            {Object.keys(templateOptions).map((o) => {
              const toList = templateOptions[o];
              return (
                <Select.OptGroup key={`to-${o}`} label={o.toUpperCase()}>
                  {toList.map((t) => (
                    <Select.Option key={t.id} value={t.id}>
                      <TaskBadge type={t.type} />
                      {t.title}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              );
            })}
          </Select>
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="title" {...validation.title}>
            Title *
          </InputLabel>
          <FormInput
            id="title"
            value={form.title}
            size="medium"
            onChange={(e) => updateForm("title", e.target.value)}
            placeholder="e.g. 30 Day Follow-up...."
            {...validation.title}
          />
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="type" {...validation.type}>
            Task Type *
          </InputLabel>
          <Select
            value={form.type}
            onChange={(val) => updateForm("type", val)}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
            {...validation.type}
          >
            <Select.Option value="general">
              <TaskBadge type="general" />
              General
            </Select.Option>
            <Select.Option value="call">
              <TaskBadge type="call" />
              Call
            </Select.Option>
            <Select.Option value="email">
              <TaskBadge type="email" />
              Email
            </Select.Option>
            <Select.Option value="text">
              <TaskBadge type="text" />
              Text Message
            </Select.Option>
          </Select>
        </Flex>

        <Flex vertical={true}>
          <InputLabel htmlFor="instructions" {...validation.instructions}>
            Instructions *
          </InputLabel>
          <TextArea
            value={form.instructions}
            onChange={(e) => updateForm("instructions", e.target.value)}
            size="large"
            autoSize={true}
            placeholder="e.g. Let customer know their program have begun..."
          />
          {form.template_id !== null ? (
            <p className="at-info">
              * Changing instructions for this task will not affect the
              templates original instructions and will only change them for this
              task.
            </p>
          ) : null}
        </Flex>
        <Flex vertical={false} gap={16}>
          <Flex vertical={true} style={{ flex: 1 }}>
            <InputLabel htmlFor="start" {...validation.start}>
              Start Task *
            </InputLabel>
            <Select
              value={form.start}
              onChange={changeStart}
              size="extra-medium"
              popupClassName="pp-select-dropdown ppsd-task"
              placeholder="---"
              {...validation.start}
            >
              {isEditing ? (
                <Select.Option value="previous">
                  After Previous Task
                </Select.Option>
              ) : null}
              <Select.Option value="start">
                {isEditing ? "After Today" : "Due Date"}
              </Select.Option>
              <Select.Option value="now">Due Immediately</Select.Option>
            </Select>
            {form.start === "previous" ? (
              <p className="at-info">
                * Due {form.delay} days after previous task is completed
              </p>
            ) : null}
            {form.start === "start" ? (
              <p className="at-info">
                {isEditing
                  ? `* Due ${form.delay} days after today`
                  : "* Due on the selected date"}
              </p>
            ) : null}
            {form.start === "now" ? (
              <p className="at-info">
                * Due immediately once the task is created/saved
              </p>
            ) : null}
          </Flex>
          {isEditing ? (
            <Flex vertical={true} style={{ width: "120px" }}>
              <InputLabel htmlFor="delay" {...validation.delay}>
                Delay *
              </InputLabel>
              <InputNumber
                disabled={form.start === "now"}
                controls={false}
                precision={0}
                value={form.delay}
                formatter={(value) => `${value} days`}
                parser={(value) => value?.replace(" days", "")}
                onChange={changeDelay}
              />
            </Flex>
          ) : (
            <Flex vertical={true} style={{ width: "150px" }}>
              <InputLabel htmlFor="due_timestamp" {...validation.due_timestamp}>
                Due Date *
              </InputLabel>
              <DatePicker
                disabled={!form.start || form.start === "now"}
                value={form.due_timestamp}
                placeholder=""
                onChange={(val) => dateChange(val)}
                format={dateFormat}
                allowClear={false}
                size="medium"
              />
            </Flex>
          )}
        </Flex>
        <Flex vertical={true}>
          <InputLabel
            htmlFor="assigned_user_id"
            {...validation.assigned_user_id}
          >
            Assign to *
          </InputLabel>
          <Select
            value={form.assigned_user_id || form.assigned_team_id}
            onChange={changeAssigned}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
            {...validation.assigned_user_id}
          >
            {teams?.map((t) => (
              <Select.Option key={t.id} value={t.id}>
                <TeamAvatar name={t.name} />
                {t.name}&nbsp;{`(${t.user_count})`}
              </Select.Option>
            ))}
            {users?.map((u) => (
              <Select.Option key={u.id} value={u.id}>
                <UserAvatar name={u.name} />
                {u.name}
              </Select.Option>
            ))}
          </Select>
          {form.assigned_team_id ? (
            <p className="at-info">
              * If the task is due, assigning a team or changing a team will
              auto-select a NEW assigned user from that team.
            </p>
          ) : null}
        </Flex>
      </div>
      <div className="new-task-actions">
        <Button block type="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          block
          type="primary"
          loading={saving}
          className="green-btn"
          disabled={!validation.allValid || !hasChanged}
          onClick={createCustomTask}
        >
          {isEditing ? "Save" : "Add"} Task
        </Button>
      </div>
    </Drawer>
  );
}

export default CustomTask;
