import React from "react";
import { flatMoney } from "_assets/js/helpers";
import {
  IconDropletHalf2,
  IconTargetArrow,
  IconBolt,
  IconAnchor,
} from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import "./OfferCard.scss";

function OfferCard({
  title,
  duration,
  cost,
  popular,
  selected,
  desktop,
  actionProps,
  openWindow,
}) {
  let icon = <IconAnchor />;
  if (duration === 24) icon = <IconDropletHalf2 />;
  if (duration === 18) icon = <IconTargetArrow />;
  if (duration === 12) icon = <IconBolt />;

  let className = `pp-offer-card ${popular ? "pp-popular" : ""}`;
  className += " duration-" + duration;

  if (selected) className += " pp-selected";

  const plans = {
    24: {
      title: "Steady Course",
      type: "relaxed",
      amount: 348,
      months: 24,
    },
    18: {
      title: "Accelerated",
      type: "moderate",
      amount: 456,
      months: 18,
    },
    12: {
      title: "Rapid Relief",
      type: "aggressive",
      amount: 632,
      months: 12,
    },
  };

  return (
    <div className={className}>
      {popular && (
        <div className="popular">
          {selected ? "SELECTED PLAN" : "MOST POPULAR"}
        </div>
      )}
      {desktop ? (
        <div className="oc-info desktop">
          <div className="oc-circle">{icon}</div>
          <div className="flat-title">{title}</div>
          <p>Debt free in {duration} months</p>
          <div className="flat-money">{flatMoney(cost)}/month</div>
        </div>
      ) : (
        <div className="oc-info">
          <div className="oc-circle">{icon}</div>
          <div className="oc-content">
            <div className="oc-top">
              <div>{title}</div>
              <div>{flatMoney(cost)}/month</div>
            </div>
            <p>Debt free in {duration} months</p>
            {selected && <p className="notice">* Approximate estimation</p>}
          </div>
        </div>
      )}
      <Button
        type="secondary"
        size="large"
        block
        onClick={() => openWindow(plans[duration?.toString()])}
      >
        View plan
      </Button>
    </div>
  );
}

export default OfferCard;
