import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@centrate-io/barn";
import { Actions, Title, SubTitle, Avatar, YesNo } from "_styleguide";
import { IconMessageQuestion } from "@tabler/icons-react";
import { validateStart } from "_helpers/validateForm";
import Unqualified from "./_components/Unqualified/Unqualified";

function Start(props) {
  const navigate = useNavigate();
  const [start, setStart] = useState({
    job: undefined,
    debt: undefined,
    bank: undefined,
  });
  const [notQualified, setNotQualified] = useState(
    localStorage?.getItem("pp_qualify") === "no" ? true : false,
  );

  const updateForm = (f, v) => {
    const n = { ...start };
    n[f] = v;
    setStart(n);
  };

  const handleClick = async () => {
    const doesQualify =
      start.job === true && start.debt === true && start.bank === true;
    localStorage.setItem("pp_qualify", doesQualify ? "yes" : "no");
    if (doesQualify) {
      navigate(props?.next);
    } else {
      setNotQualified(true);
    }
  };

  const validation = validateStart(start);

  return notQualified ? (
    <Unqualified {...props} reset={() => setNotQualified(false)} />
  ) : (
    <div className="step-start">
      <Avatar icon={<IconMessageQuestion />} />
      <Title>Pre-Qualify for Tailored Debt Relief Strategies</Title>
      <div className="app-form">
        <div className="app-yesno">
          <SubTitle>Do you have a full-time or part-time job?</SubTitle>
          <YesNo value={start.job} onChange={(val) => updateForm("job", val)} />
        </div>
        <div className="app-yesno">
          <SubTitle>Are you in $10,000 or more of credit card debt?</SubTitle>
          <YesNo
            value={start.debt}
            onChange={(val) => updateForm("debt", val)}
          />
        </div>
        <div className="app-yesno">
          <SubTitle>Do you have a bank account?</SubTitle>
          <YesNo
            value={start.bank}
            onChange={(val) => updateForm("bank", val)}
          />
        </div>
      </div>
      <Actions
        {...props}
        noPowered
        showLogo
        customNext={
          <Button
            disabled={!validation.allValid}
            type="primary"
            size="large"
            block
            onClick={handleClick}
            className="green-btn"
          >
            Next Step
          </Button>
        }
      />
    </div>
  );
}

export default Start;
