import React from "react";
import { Title, Avatar } from "_styleguide";
import { IconAlertTriangle, IconHeadset } from "@tabler/icons-react";
import "./Invalid.scss";

function Invalid(props) {
  return (
    <div className="step-start step-invalid">
      <Avatar icon={<IconAlertTriangle />} warning />
      <Title>Verification Incomplete: Action Required</Title>
      <p className="invalid-info">
        We were unable to verify your details. Your application process has
        paused due to security concerns.
        <br />
        <b>Your security is our top priority.</b>
      </p>
      <div className="invalid-box">
        <IconHeadset />
        <p>Call our support team to verify your information.</p>
        <div className="invalid-phone">
          <a href="tel:+16342494492">1-634-249-4492</a>
        </div>
      </div>
    </div>
  );
}

export default Invalid;
