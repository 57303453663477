import React from "react";
import { Button } from "@centrate-io/barn";
import { Actions, Title, Avatar } from "_styleguide";
import { IconX } from "@tabler/icons-react";
import "./Unqualified.scss";

function Unqualified(props) {
  return (
    <div className="step-start step-unqualified">
      <Avatar icon={<IconX />} danger />
      <Title>
        We’re sorry, unfortunately you don’t qualify to be in our relief
        program.
      </Title>
      <p className="unqualified-info">
        Our credit relief program requires a minimum of <b>$10,000</b> in
        eligible credit lines, a <b>full or part-time job</b> and a{" "}
        <b>bank account.</b>
      </p>
      <Actions
        {...props}
        noPowered
        showLogo
        customNext={
          <Button type="secondary" size="large" block onClick={props.reset}>
            Back to Previous Screen
          </Button>
        }
      />
    </div>
  );
}

export default Unqualified;
