import React from "react";
import { Actions } from "_styleguide";
import { validatePhone } from "_helpers/validateForm";
import { Avatar, Title, FormInput, InputLabel } from "_styleguide";
import { IconPhone } from "@tabler/icons-react";
import "./Phone.scss";

function Phone(props) {
  const { form, u } = props;

  const validation = validatePhone(form);

  return (
    <div className="step-start step-phone">
      <Avatar icon={<IconPhone />} />
      <Title>How can we get in contact with you?</Title>
      <div className="app-form">
        <div className="form-item">
          <InputLabel htmlFor="phone" {...validation.phone}>
            Primary phone number *
          </InputLabel>
          <FormInput
            id="phone"
            value={form.phone}
            onChange={(e) => u("phone", e.target.value)}
            size="large"
            placeholder="e.g. 631-294-2293..."
            {...validation.phone}
          />
          <div className="small-note">
            * Enter the number you use most frequently.
          </div>
        </div>
      </div>
      <Actions
        {...props}
        form={form}
        disabledNext={!validation.allValid}
        noPowered
        showLogo
      />
    </div>
  );
}

export default Phone;
