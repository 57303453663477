import React from "react";
import { Actions, Autocomplete, Select } from "_styleguide";
import { validateLocation } from "_helpers/validateForm";
import { Avatar, Title, FormInput, InputLabel } from "_styleguide";
import { IconMap2 } from "@tabler/icons-react";

import { STATES } from "_helpers/location";

function Location(props) {
  const { form, ua } = props;

  const validation = validateLocation(form);

  return (
    <div className="step-start">
      <Avatar icon={<IconMap2 />} />
      <Title>Let’s get to know who you are bit more, pt. 2</Title>
      <div className="app-form">
        <div className="form-item">
          <InputLabel {...validation.address}>Address *</InputLabel>
          <Autocomplete
            value={form.address}
            onChange={(v) => ua({ address: v })}
            updateAddress={ua}
            size="large"
            {...validation.address}
          />
          <FormInput
            id="address_line_two"
            value={form.address_line_two}
            onChange={(e) => ua({ address_line_two: e.target.value })}
            placeholder="Apartment, suite, unit (optional)"
            size="large"
            style={{ marginTop: "16px" }}
          />
        </div>
        <div className="form-item">
          <InputLabel htmlFor="city" {...validation.city}>
            City *
          </InputLabel>
          <FormInput
            id="city"
            value={form.city}
            onChange={(e) => ua({ city: e.target.value })}
            size="large"
            {...validation.city}
          />
        </div>
        <div className="form-row">
          <div>
            <InputLabel htmlFor="state" {...validation.state}>
              State *
            </InputLabel>
            <Select
              value={form.state}
              onChange={(val) => ua({ state: val })}
              size="large"
              popupClassName="pp-select-dropdown"
              {...validation.state}
            >
              {STATES.map((s) => (
                <Select.Option key={s.value} value={s.value}>
                  {s.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div style={{ maxWidth: "160px" }}>
            <InputLabel htmlFor="zip" {...validation.zip}>
              Zip *
            </InputLabel>
            <FormInput
              id="zip"
              value={form.zip}
              onChange={(e) => ua({ zip: e.target.value })}
              size="large"
              maxLength="5"
              {...validation.zip}
            />
          </div>
        </div>
      </div>
      <Actions
        {...props}
        form={form}
        disabledNext={!validation.allValid}
        noPowered
        showLogo
      />
    </div>
  );
}

export default Location;
