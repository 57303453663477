import React from "react";
import { Text } from "@centrate-io/barn";
import { LoadingOutlined } from "@ant-design/icons";
import { Title } from "_styleguide";
import Equifax from "_assets/logos/equifax.svg";
import "./CreditLoading.scss";

function CreditLoading(props) {
  return (
    <div className="step-start pp-experian">
      <Title>Please wait while we process your info through Equifax...</Title>
      <div className="app-loading">
        <div className="spinner">
          <LoadingOutlined style={{ fontSize: 80 }} spin />
        </div>
        <Text>You’re on your way to financial freedom!</Text>
      </div>
      <div className="pp-experian-logo">
        <img src={Equifax} alt="Equifax" />
      </div>
    </div>
  );
}

export default CreditLoading;
