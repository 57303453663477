import React, { useState } from "react";
import { Title, FAQ } from "_styleguide";
import { FAQList } from "./_data/FAQ";
import OfferCard from "./_components/OfferCard/OfferCard";
import InfoPopup from "./_components/InfoPopup/InfoPopup";
import { PLAN_INCLUDES } from "_helpers/plan";
import { IconStarFilled } from "@tabler/icons-react";
import "./Offers.scss";

function Offers(props) {
  const [showWindow, setShowWindow] = useState(false);

  const openWindow = (plan) => setShowWindow(plan);
  const closeWindow = () => setShowWindow(false);

  const offerCardProps = {
    actionProps: props,
    openWindow,
  };

  return (
    <React.Fragment>
      {showWindow ? (
        <InfoPopup
          plan={showWindow}
          actionProps={props}
          closeWindow={closeWindow}
        />
      ) : null}
      <div className="step-start step-offers">
        <Title>
          Choose the perfect plan to unlock your path to financial freedom!
        </Title>
        <p className="offer-select-info">
          Select one of the flexible payment options below to start your journey
          towards financial stability on your own terms.
        </p>
        <div className="app-form wide offer-cards desktop">
          <OfferCard
            {...offerCardProps}
            title="Accelerated"
            duration={18}
            cost={456}
            desktop
            openWindow={openWindow}
          />
          <OfferCard
            {...offerCardProps}
            title="Steady Course"
            duration={24}
            cost={348}
            popular
            desktop
          />
          <OfferCard
            {...offerCardProps}
            title="Rapid Relief"
            duration={12}
            cost={632}
            desktop
          />
        </div>
        <div className="app-form wide offer-cards mobile">
          <OfferCard
            {...offerCardProps}
            title="Steady Course"
            duration={24}
            cost={348}
            popular
          />
          <OfferCard
            {...offerCardProps}
            title="Accelerated"
            duration={18}
            cost={456}
          />
          <OfferCard
            {...offerCardProps}
            title="Rapid Relief"
            duration={12}
            cost={632}
          />
        </div>
        <div className="offer-includes wide">
          <h5>ALL PLANS INCLUDE:</h5>
          <div className="bullets">
            {PLAN_INCLUDES.map((p, i) => (
              <div key={"po-" + i} className="bullet">
                <IconStarFilled />
                {p}
              </div>
            ))}
          </div>
        </div>
        <div className="app-form app-faq">
          <h4>Frequently Asked Questions</h4>
          <FAQ items={FAQList} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Offers;
