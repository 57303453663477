export const FAQList = [
  {
    key: "1",
    label: "What are the benefits of joining the program?",
    children:
      "There are many benefits than come with our program such as legal representation from our expert team of qualified attorneys, stopping creditor harassment, defense against lawsuits and even advice on credit repair solutions. We also offer several flexible payment options to choose from. Beyond just addressing your current debt issues, we often provide their clients with education and resources on financial management to prevent future debt problems.",
  },
  {
    key: "2",
    label: "What happens if I can’t keep up with the payments?",
    children:
      "If you struggle with program payments, it's important to communicate this with your advisor as soon as you can. We can often work with you to adjust your payment plan, but failing to make payments can lead to program termination and potentially further financial difficulties.",
  },
  {
    key: "3",
    label: "Can I choose when I begin paying?",
    children:
      "Yes, we offer all clients the ability to select a date that makes most sense for them to being making payments within a 30 day window. Once a payment is made, this will be the official start date of the plan.",
  },
  {
    key: "4",
    label: "Will enrolling in the program stop creditors from harassing me?",
    children:
      "Yes, enrolling in our debt relief program will help reduce or stop harassment from creditors. When you enter into the program, our experts will often communicate with creditors on your behalf and once informed of legal representation, they're then required to communicate through your representative.",
  },
  {
    key: "5",
    label: "I’m being threatened with a lawsuit, can you help?",
    children:
      "Yes, having legal representation can significantly help if you're being threatened with a lawsuit over debt. Knowing you have professional legal representation can provide significant peace of mind during a stressful time. Your lawyer can handle the complexities of your case, allowing you to focus on other aspects of your life.",
  },
  {
    key: "6",
    label: "How do you negotiate with creditors on my behalf?",
    children:
      "Negotiating with creditors is a nuanced process that requires a strategic approach, understanding of financial laws, and often, a bit of persistence. We follow a process that has proven to have favorable outcomes for our clients by way of assessing your financial situation and rigorously negotiating with creditors to agree to significantly smaller or a 100% relief of debt.",
  },
];
