import React from "react";
import { Button } from "@centrate-io/barn";
import { Actions } from "_styleguide";
import Equifax from "_assets/logos/equifax.svg";
import { Avatar, Title } from "_styleguide";
import { IconLock } from "@tabler/icons-react";

function RunReport(props) {
  return (
    <div className="step-start step-credit">
      <Avatar icon={<IconLock />} />
      <Title>
        Soft Credit Check, will <u>NOT</u> effect your credit score.
      </Title>
      <div className="app-form">
        {true && (
          <div className="experian-button">
            Brought to you by <img src={Equifax} alt="Equifax" />
          </div>
        )}
        <div className="credit-confirmation">
          You understand that by clicking on the{" "}
          <b>“I Agree, Run Soft Credit Inquiry”</b> button immediately following
          this notice, you are providing ‘written instructions’ to Paypath under
          the Fair Credit Reporting Act authorizing Paypath to obtain
          information from your personal credit report or other information from
          Equifax. You authorize Paypath to obtain such information solely to
          conduct a pre-qualification for credit.
        </div>
      </div>
      <Actions
        {...props}
        form={props.form}
        noPrevious
        noPowered
        showLogo
        customNext={
          <Button
            loading={props.loadingReport}
            type="primary"
            size="large"
            block
            onClick={props.handleClick}
            className="green-btn"
          >
            I Agree, Run Soft Credit Check
          </Button>
        }
      />
    </div>
  );
}

export default RunReport;
